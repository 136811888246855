import { useEffect } from "react";
import DataCard from "../../../Components/General/DataCard/DataCard";
import "./Overview.scss";
import { useDispatch, useSelector } from "react-redux";
import { getAdminAnalytics } from "../../../Actions/admin";
import Card from "../../../Components/General/Card/Card";

function Overview() {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAdminAnalytics());
    }, [])

    const { analytics } = useSelector(state => state.admin);

    return (
        <div className="dashboard-page overview-page-container table-page-container">
            <div className="subtitle-container">
                <span className="dashboard-subtitle">Analytics</span>
            </div>
            <div className="analytics-overview-container">
                {
                    analytics && <>
                        <DataCard title="Members" data={analytics.users} />
                        <DataCard title="Fulfilled Orders" data={analytics.fulfilled} />
                        <DataCard title="Unfulfilled Orders" data={analytics.unfulfilled} />
                        <DataCard title="Resi Usage (GB)" 
                            subTitle={`since ${analytics.usageSince}`}
                            data={Math.round(analytics.usage*100)/100?.toLocaleString('en-US')} 
                        />
                    </>
                }
            
                <Card classes="table-card mt-0">
                    <table>
                        <tbody>
                            <tr>
                                <th>Month</th>
                                <th>Resi Usage</th>
                            </tr>
                            {
                                analytics && analytics.sixMonthsUsage.map((user, i) => (
                                    <tr key={`user-${i}`}>
                                        <td>{new Date(user.month).toLocaleString('en-US', { month: 'long', year: 'numeric' })}</td>
                                        <td>{Math.round(user.usage*100)/100?.toLocaleString('en-US')} GB</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </Card>
            </div>
            
        </div>
    )
}

export default Overview;